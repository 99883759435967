.pseudo-link {

	display: inline;

	.link {
		cursor: pointer;
		color: #20a8d8;
		padding: 0 1rem;
		display: inline-block;
	}

	.link:hover {
		color: #167495;
	}
}