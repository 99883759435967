.toolbox.right {
	right: 2.5rem;
}

.toolbox.left {
	left: 1.0rem;
}

.toolbox {
	width: fit-content;
	margin: 0;
	position: absolute;
	top: 0.7rem;
	font-size: 130%;
	z-index: 10000;

	.buttons {
		border-radius: 5px;
		display: inline-block;
		margin: 0 0.7rem 0 0;
		cursor: pointer;
	}

	.buttons:hover {
		color: #167495;
		font-size: 130%;
	}

	.input-container {
		display: inline-block;
		position: absolute;
		padding-left: 35px;
		cursor: pointer;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		font-weight: 700;
	}

	/* Hide the browser's default checkbox */
	.input-container input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	/* Create a custom checkbox */
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 25px;
		width: 25px;
		background-color: #eee;
		border: 1px solid #ccc;
		border-radius: 5px;
	}

	/* On mouse-over, add a grey background color */
	.input-container:hover input ~ .checkmark {
		background-color: #ccc;
	}

	/* When the checkbox is checked, add a blue background */
	.input-container input:checked ~ .checkmark {
		background-color: #2196F3;
		opacity: 0.4;
	}

	/* Create the checkmark/indicator (hidden when not checked) */
	.checkmark:after {
		content: "";
		position: absolute;
		display: none;
	}

	/* Show the checkmark when checked */
	.input-container input:checked ~ .checkmark:after {
		display: block;
	}

	/* Style the checkmark/indicator */
	.input-container .checkmark:after {
		left: 9px;
		top: 5px;
		width: 5px;
		height: 10px;
		border: solid white;
		border-width: 0 3px 3px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}

}