.leaflet-controls.layers-control {
	.input-container {
		display: block;
		position: relative;
		padding-left: 35px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		font-weight: 700;
	}

	/* Hide the browser's default checkbox */
	.input-container input {
		position: absolute;
		opacity: 0;
		height: 0;
		width: 0;
	}

	.input-container.active, .input-container.active input {
		cursor: pointer;
	}

	label.input-container.disabled {
		opacity: 0.5;
	}

	/* Create a custom checkbox */
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 25px;
		width: 25px;
		background-color: #eee;
		border-radius: 5px;
	}

	.checkmark.radio {
		border-radius: 100%;
	}

	/* On mouse-over, add a grey background color */
	.input-container.active:hover input ~ .checkmark {
		background-color: #ccc;
	}

	/* When the checkbox is checked, add a blue background */
	.input-container input:checked ~ .checkmark {
		background-color: #2196F3;
		opacity: 0.4;
	}

	/* Create the checkmark/indicator (hidden when not checked) */
	.checkmark:after {
		content: "";
		position: absolute;
		display: none;
	}

	/* Show the checkmark when checked */
	.input-container input:checked ~ .checkmark:after {
		display: block;
	}

	/* Style the checkmark/indicator */
	.input-container .checkmark:after {
		left: 10px;
		top: 5px;
		width: 5px;
		height: 10px;
		border: solid white;
		border-width: 0 3px 3px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
}
