.file-browser {

	.tr-select {
		cursor: pointer;
	}

	.tr-select:hover {
		background-color: aliceblue;
		background-radius: 5px;
	}

}