@charset "UTF-8";
.file-browser .react-contextmenu {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: .25rem;
  color: #373a3c;
  font-size: 12px;
  margin: 2px 0 0;
  min-width: 120px;
  outline: none;
  opacity: 0;
  padding: 5px 0;
  pointer-events: none;
  text-align: left;
  transition: opacity 250ms ease !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); }

.file-browser .react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
  z-index: 9999; }

.file-browser .react-contextmenu-item {
  background: 0 0;
  border: 0;
  color: #373a3c;
  cursor: pointer;
  font-weight: 400;
  line-height: 1.5;
  padding: 3px 10px;
  text-align: inherit;
  white-space: nowrap; }

.file-browser .react-contextmenu-item.react-contextmenu-item--active,
.file-browser .react-contextmenu-item.react-contextmenu-item--selected {
  color: #fff;
  background-color: #20a0ff;
  border-color: #20a0ff;
  text-decoration: none; }

.file-browser .react-contextmenu-item.react-contextmenu-item--disabled,
.file-browser .react-contextmenu-item.react-contextmenu-item--disabled:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
  color: #878a8c; }

.file-browser .react-contextmenu-item--divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  cursor: inherit;
  margin-bottom: 3px;
  padding: 2px 0; }

.file-browser .react-contextmenu-item--divider:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15); }

.file-browser .react-contextmenu-item.react-contextmenu-submenu {
  padding: 0; }

.file-browser .react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
  content: "▶";
  display: inline-block;
  position: absolute;
  right: 7px; }

.file-browser .example-multiple-targets::after {
  content: attr(data-count);
  display: block; }
