.aside-menu {
	.aside-menu-in-full-content {

		.toggle-button {
			position: absolute;
			top: 5px;
			left: 5px;
			padding: 0.25rem;
		}

		.attribute-button {
			width: 80%;
			margin-bottom: 1em;

			.image {
				width: 1.5em;
				margin-left: 1em;
			}
		}

		.topic-button {
			width: 70%;
			margin-bottom: 1em;
			margin-left: 3.5em;
			margin-right: 1em;

			.image {
				width: 1.5em;
				margin-left: 1em;
			}
		}

		.custom-switch{
			padding-top: 0.5em;
		}
	}
}
