.ppcity-admin {

	[role=button] {
		cursor: pointer;
	}

	[role=button]:hover {
		color: #167495;
		background-image: none !important;
	}

	label {
		color: blue;
		font-size: 90%;
		font-style: italic;
	}

	fieldset:disabled label {
		color: #c8ced3;
	}

	fieldset {
		min-width: inherit;
		padding: inherit;
		margin: inherit;
		border: inherit;
		border-width: 2px;
		border-radius: 3px;
		border-style: groove;
		border-color: #c8ced3;

		label.form-check-label {
			color: darkslategray;
			font-weight: 800;
		}
	}

	legend {
		width: inherit;
		max-width: inherit;
		margin-bottom: inherit;
		font-size: 110%;
		font-style: italic;
		padding-inline-start: 0.4rem;
		padding-inline-end: 0.4rem;
	}

	.icon-box {
		width: 40px;
		min-width: 40px;
		height: 40px;
		min-height: 40px;
		padding: 2px;
		border: 1px solid lightgray;
		margin: 0;
		display: inline-block;

		.text {
			font-size: 75%;
			color: #c8ced3;
			margin: auto;
		}
	}

	.topic {
		border: 1px solid lightgray;
	}

	.marker-preview-container {
		line-height: 64px;
		border: 1px dotted lightgray;
		border-radius: 5px;
		margin-bottom: 0.8rem;
	}

	.marker-preview {
		width: 48px;
		max-height: 48px;
		display: inline-block;
		margin-right: 0.8rem;
		cursor: pointer;
	}

	.marker-preview:hover {
		width: 60px;
		max-height: 60px;
		margin-right: calc(0.8rem - 12px);
	}

	.opacity-50 {
		opacity: 0.5;
	}

}
