.target-setting {

	button {
		background-color: transparent;
		border-width: 0;
	}

	button:hover, button:active {
		color: #167495;
		background-color: transparent;
	}

	.dropdown-menu {

		label {
			color: inherit;
		}

		.form-check {
			margin-left: 1rem;
		}

		label:hover {
			color: #167495;
		}

		.dropdown-item {
			padding: 0.25rem 1.5rem;

			i {
				color: #167495 !important;
			}
		}

		.dropdown-item:hover {
			background-color: #f0f3f5;
		}
	}
}

.target-setting.show button.dropdown-toggle {
	color: #167495 !important;
	background-color: transparent !important;
	border-width: 0;
}
