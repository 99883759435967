.answers {
	background-color: rgba(200,200,200,0.9);
	z-index: 1017;

	h1 {
		color: white;
		border-color: rgb(100,100,100);
		border-radius: 5px;
	}

	header {
		width: 100%;
		background-color: rgba(100,100,100,0.5);
		border-radius: 0 0 5px 5px;
		padding-top: 5px;

		i.toggle {
			font-size: 200%;
			position: absolute;
			top: 5px;
			right: 10px;
			cursor: pointer;
		}

		i.toggle:hover {
			color: darkred;
		}

		i.toggle::after {
			clear: both;
		}

		.header-icon {
			width: 50px;
			display: block;
			margin: 0 auto;
		}
	}

	.tab-content {
		background-color: transparent;
		color: white;
		font-weight: 600;
		max-width: 100vw;
		overflow-y: auto !important;
		max-height: calc(100% - 40px);
	}

	.apexcharts-menu.apexcharts-menu-open {
		background-color: rgba(0,0,0,0.5);
	}
	.apexcharts-theme-light {
		.apexcharts-menu-item:hover {
			color: lightblue;
		}
		.apexcharts-tooltip-title {
			background-color: #d4d7d9 !important;
		}
		.apexcharts-tooltip-y-group {
			color: black;
		}
	}
}