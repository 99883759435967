.social {
  *[role="reaction"] {
    cursor: pointer;
    padding: 0 0.3rem;
    margin-right: 0.1rem;
    color: inherit;
  }
  *[role="reaction"].disabled {
    pointer-events: none;
  }
}